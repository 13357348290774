<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class=" col-10 mb-0 col-sm-9 col-lg-10">
                <p class="aboutMeMobile" style="margin-left: 20px;">About Me</p>
            </div>
            <div class="col-2 col-sm-3 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStyleDoctor">
        <div class="row">
            <div class="col-12 proflemobile">
                <input type="file" style="display:none" id="doctor_img" name="doctor_img" />
                <img src="~@/assets/img/images/doctor.png" style="width:100px;height:100px">
                <!-- <div class="my-2">
                    <button class="btn text-white" style="background:#34989f;">Upload Photo</button>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 my-2">
                <label>First name <span class="star">*</span> </label>
                <input type="text" id="firstName" name="firstName"  v-bind:class="[ this.userPayload.isActive ? 'hide-cursor' : '']" :disabled="this.userPayload.isActive" v-model="firstName"
                    autocomplete="off" class="form-control UpdateTextAlignment" />
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 my-2">
                <label>Last name <span class="star">*</span> </label>
                <input type="text" id="lastName" name="lastName"  v-bind:class="[this.userPayload.isActive  ? 'hide-cursor' : '']" :disabled="this.userPayload.isActive" v-model="lastName"
                    autocomplete="off" class="form-control UpdateTextAlignment" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-sm-6 my-2">
                <label>Council where registered <span class="star">*</span> </label>
                <input type="text" id="council" name="council"  v-bind:class="[this.userPayload.speciality  ? 'hide-cursor' : '']" :disabled="this.userPayload.speciality" v-model="council"
                    autocomplete="off" class="form-control UpdateTextAlignment" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2" style="margin-top:auto;">
                <label style="margin-bottom:5px;">Registration Number <span class="star">*</span></label>
                <input type="text" v-model="registerNo"
                    v-bind:class="[this.userPayload.speciality  ? 'hide-cursor' : '']" :disabled="this.userPayload.speciality"
                    id="registerNo" name="registerNo" class="form-control UpdateTextAlignment" />
            </div>
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 my-2">
                <label>Educational qualification <span class="star">*</span> <span style="font-size:14px;color:#000">(Please
                        seperate
                        your degrees by a comma)</span></label>
                <input type="text" v-model="educational" id="educational" name="educational"
                    class="form-control UpdateTextAlignment" v-bind:class="[adminAction ? 'hide-cursor' : '']"
                    :disabled="this.userPayload.speciality" />
            </div>
        </div>
        
        <div class="row ">
            <div class="col-lg-5 col-md-5 col-8 my-2">
                <div class="form-group">
                    <label>Mobile number<span class="star">*</span></label>
                    <input type="text"  @input="mobileNumberValidateHcp" :disabled="this.userPayload.isActive" v-model="mobile" maxlength="10" v-on:keyup.enter="isMobileValidHcp ? createClinician() : null" id="mobile" name="mobile"
                        class="form-control UpdateTextAlignment"  v-bind:class="[this.userPayload.isActive  ? 'hide-cursor' : '']" />
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-4 my-2">
                <button type="button" class="btn btn-blue-color"  :disabled="true" @click="verifyEmail"
                    style="color: white ; background: #cccccc !important;margin-top:23px;pointer-events: none;" >Verify</button>
            </div>
    
            <div class="col-lg-5 col-md-5 my-2">
                <div class="form-group primarystyle">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="primaryRadio" type="checkbox" name="primary_phone_check" 
                            id="primary_phone_check" :value="true" style="margin-left:5px;cursor: pointer;" />
                        <label class="form-check-label" for="primary_phone_check" style="margin-left:7px">Whatsapp</label>
                    </div>
                </div>
            </div>

        </div>

        <div class="row ">
            <div class="col-lg-5 col-md-5 col-8 my-2">
                <div class="form-group">
                    <label>Mail ID <span class="star">*</span></label>
                    <input type="email" @input="handleEmailInput"   v-bind:class="[this.userPayload.isActive? 'hide-cursor' : '']"
                        v-model="email"
                        class="form-control ban-cursor UpdateTextAlignment" :disabled="this.userPayload.isActive" />
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-4 my-2">
                <button type="button" class="btn btn-blue-color"  :disabled="true" @click="verifyEmail"
                    style="color: white ; background: #cccccc !important;margin-top:23px;pointer-events: none;" >Verify</button>
            </div>
            <div class="col-lg-5 col-md-5 my-2">
                <div class="form-group primarystyle">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="primary_email_check" id="primary_email_check"
                            :value="true" style="margin-left:5px;cursor: pointer;">
                        <label class="form-check-label" for="primary_email_check" style="margin-left:7px">Primary</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-6 my-2" style="margin-top:auto;">
                <label>Pincode <span class="star">*</span>
                </label>
                <input type="text" maxlength="6" :disabled="this.userPayload.speciality"  v-bind:class="[this.userPayload.speciality ? 'hide-cursor' : '']" v-model="pincode" id="pincode" name="pincode"
                    class="form-control UpdateTextAlignment" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <h5>My speciality <span class="star">*</span></h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-lg-4" v-for="(checkOption, index) in practiceAreas" :key="index">
                <label>
                    <input type="radio" :id="checkOption.id" :value="checkOption.id" v-model="speciality" class="me-2 practiceBox" name="pracriceAreas" 
                        v-bind:class="[this.userPayload.speciality ? 'hide-cursor' : '']"
                        :disabled="this.userPayload.speciality"  />
                    {{ checkOption.practiceArea }}
                </label>
            </div>
        </div>
        <div class="row"  style="padding-bottom: 15px;">
            <div class="col-12 col-md-6 col-lg-6 col-sm-6  mt-3">
                <button class="btn btn-blue-color text-white" :disabled="this.userPayload.speciality"  @click="createClinician()"
                    style="width:100%">Submit</button>
            </div>
        </div>
       
    </div>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
    props: [" practicebox(id)"],
    data() {
        return {
            firstName:'',
            lastName:'',
            email:'',
            educational:'',
            registerNo:'',
            mobile:'',
            pincode: '',
            council:'',
            userPayload: {},
            save: false,
            addNumber: false,
            addEmail: false,
            primaryRadio: false,
            click: false,
            adminAction: false,
            primaryEmail: false,
            clinicianOthersID:'',
            practiceAreas:[],
            speciality:''
        }
    },
    created: function () {
        this.currentRoute = this.$route.path.split('/')[2];
        this.tagId = this.$route.query.id;
        let clinicianothers = localStorage.getItem('clinician-others-id')
        this.clinicianOthersID = JSON.parse(clinicianothers);
        if(this.clinicianOthersID){
        this.getUserDetail();
        }
    this.getPracticeArea();
    },
    computed: {
    },
    methods: {
        mobileNumberValidateHcp(){
    this.mobile = this.mobile.replace(/\D/g, '');
  },
        handleEmailInput() {
      this.email =  this.email.toLowerCase();
    },
        GoSetting() {
            window.scrollTo(0, 0);
            this.$router.push(`/clinicianothers/mobile-settings`);
        },


        async createClinician() {
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
          
            if (!this.council) {
                this.$swal("Please enter the council where registered ");
                return
            }
            if (!this.registerNo) {
                this.$swal("Please enter the register number!");
                return
            }
            if (!this.educational) {
                this.$swal("Please enter the education qualification!");
                return
            }
            if (!this.pincode) {
                this.$swal("Please enter the pincode!");
                return
            }
            if (!this.speciality) {
                this.$swal("Please enter the speciality!");
                return
            }
            this.click = true
            this.adminAction = true
            let updatePayload = {
                pincode: this.pincode,
                council:this.council,
                registerNo: this.registerNo,
                educational: this.educational,
                speciality:this.speciality,
            };
            if (this.primaryRadio) {
        updatePayload.message_type = 'WhatsApp';
    }
            axios
        .put(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/update`, updatePayload)
        .then(async response =>{
            localStorage.setItem('clinician-others-id', response?.data.id)
            Swal.fire({
              title: "Profile updated successfully",
              confirmButtonColor: "#7066e0",
              confirmButtonText: "OK",
              }).then((result) => {
            if (result.isConfirmed) {
            window.scrollTo(0, 0);
            this.$router.push('/clinicianothers/index')
             }
            });
             return response.data
            })
            .catch(function (error) {
                return error
            });
           
        },
        async getUserDetail() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.userPayload = response.data;
                        this.firstName=this.userPayload.firstName
                        this.lastName=this.userPayload.lastName
                        this.council = this.userPayload.council
                        this.registerNo=this.userPayload.registerNo
                        this.educational=this.userPayload.educational
                        this.mobile=this.userPayload.mobile
                        this.email=this.userPayload.email
                        this.pincode = this.userPayload.pincode,
                        this.speciality = this.userPayload.speciality.id
                        if (this.userPayload.mobile &&this.userPayload.message_type === 'WhatsApp') {
                            this.primaryRadio = true
                        } }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
            
        },
        async getPracticeArea() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/hcp-types/all`)
                .then((response) => {
                    if (response?.data) {
                        response?.data.map((data) => {
                            if (data.hcpTypes == 'clinician - others') {
                                this.practiceAreas.push(data);
                            }
                        })
                    }
                })
                .catch(function (error) {
                    return error
                });
        },
       
    }
};
</script>
<style>
.practiceBox{
    cursor: pointer;
}
.star {
    color: red;
    font-size: 20px;
}

.hide-cursor {
    cursor: not-allowed;
}

/* .form-control.cursor {
    cursor: not-allowed;
} */

.UpdateTextAlignment {
    text-align: left;
}

.primarystyle {
    margin-top: 30px;
}


@media screen and (max-width:991px) {
    .mobileStyleDoctor {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 6rem;
    }
    .aboutMeMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 1rem;
        color: #000;
        font-size: 21px;
        font-weight: 550;
    }

    .proflemobile {
        margin-top: 15px;
    }
}

@media screen and (max-width:767px) {
    .primarystyle {
        margin-top: 0px;
    }
}

</style>